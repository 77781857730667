import React from 'react';
import classNames from 'styles/utils/classNames';
import { PillProps } from './props';

const Pill: React.FC<PillProps> = ({
  type = 'default',
  text,
  onClick,
  mode,
  selected,
  icon,
  size = 'md',
  className,
}) => {
  const baseClasses = 'select-none gap-1 transition-all inline-flex items-center rounded-full';

  const sizeClasses =
    size === 'sm'
      ? 'typography-product-button-label-small px-2 py-1.5'
      : 'typography-product-button-label-medium px-4 py-2';

  const typeClasses = {
    success: 'bg-green-100 text-green-500',
    critical: 'bg-red-100 text-red-500',
    brand: 'bg-color-brand-primary text-white',
    default:
      'bg-color-bg-lightmode-secondary text-color-text-lightmode-secondary dark:bg-color-bg-darkmode-secondary dark:text-color-text-darkmode-secondary',
  };

  const selectClasses = selected
    ? 'bg-color-brand-primary text-white'
    : 'shadow-inner !shadow-[inset_0_0_0_1px_rgb(0,0,0)] dark:!shadow-[inset_0_0_0_1px_rgb(255,255,255)]';

  const pillClasses = classNames(
    baseClasses,
    sizeClasses,
    mode === 'select' ? selectClasses : typeClasses[type],
    onClick && 'cursor-pointer',
    className,
  );

  return (
    <span className={pillClasses} onClick={onClick}>
      {icon && <span>{icon}</span>}
      {text}
    </span>
  );
};

export default Pill;
